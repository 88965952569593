import axios from 'axios';
import Container from '@components/ui/container';
import Subscription from '@blocks/SubscribeToNucleusCTA';
import { ArticleGrid } from '@components/articles/article-grid';
import Divider from '@blocks/Divider';
import ArticleFilterInputs from '@containers/articles-with-filter-inputs';
import ArticleCategoryHighlights from '@containers/articles-with-category-highlights';
import Text from '@components/ui/text';
import { IoMdReturnRight } from 'react-icons/io';

import useHasMounted from '@utils/use-has-mounted';
import Template from '@components/layout/template';
import { Post, Tag } from '@payload/types';
import { Category } from '@constants/types';

interface IProps {
  articles: Post[];
  categories: Category[];
  tags: Tag[];
}

export default function BlogPage({ articles, categories, tags }: IProps) {
  const hasMounted = useHasMounted();
  if (!hasMounted) return null;

  return (
    <Template>
      <Container>
        <ArticleCategoryHighlights articles={articles} />

        <Divider />

        <div className="flex justify-start items-center mb-4">
          <IoMdReturnRight className="text-black text-lg mt-1.5 mr-2" />
          <Text variant={'subHeading'}>Nucleus: Latest</Text>
        </div>

        <ArticleFilterInputs categories={categories} technologies={tags} />
        <div className="py-4" />
        <ArticleGrid />
      </Container>
      <Divider withLine={true} />
      <Subscription />
    </Template>
  );
}

export const getStaticProps = async () => {
  const fetchArticles = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API_URL}/posts?where[status][equals]=published&sort=-publishedDate`,
  });
  const articles = await fetchArticles.data.docs;

  const fetchCategories = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API_URL}/categories?limit=20&sort=-order`,
  });

  const categories = await fetchCategories.data.docs;

  const fetchTags = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API_URL}/tags?limit=20`,
  });

  const tags = await fetchTags.data.docs;

  return {
    props: { articles, categories, tags },
    revalidate: 1,
  };
};
