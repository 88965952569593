import axios from 'axios';
import { Category } from '@constants/types';
import { API_ENDPOINTS } from '@constants/api-endpoints';
import { useQuery } from 'react-query';

export const fetchOrderedCategories = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;
  const response = await axios({
    method: 'GET',
    url: API_ENDPOINTS.CATEGORIES_ORDERED,
  });

  const categories = await response.data.docs;

  return categories;
};
export const useOrderedCategoriesQuery = (options?: any) => {
  return useQuery<[categories: Category[]], Error>(
    [API_ENDPOINTS.CATEGORIES_ORDERED, options],
    fetchOrderedCategories
  );
};
