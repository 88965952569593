import ResourceCardLoader from '@components/ui/loaders/resource-card-loader';

interface Props {
  limit?: number;
  uniqueKey?: string;
}

const ResourceItemLoader = ({ limit = 5, uniqueKey = 'resource' }: Props) => {
  return (
    <>
      {Array.from({ length: limit }).map((_, idx) => (
        <ResourceCardLoader key={idx} uniqueKey={`${uniqueKey}-${idx}`} />
      ))}
    </>
  );
};

export default ResourceItemLoader;
