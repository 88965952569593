import Button from '@components/ui/button';
import type { FC } from 'react';

import { useRouter } from 'next/router';
import { Article } from '@constants/types';
import { useArticlesPaginatedQuery } from '@queries/articles/get-all-articles-paginated-query';
import Grid from '@components/grid';
import ResourceItem from '../resource-item';
import ResourceItemLoader from '@components/ui/loaders/resource-feed-loader';

interface ArticleGridProps {
  className?: string;
}

export const ArticleGrid: FC<ArticleGridProps> = () => {
  const { query } = useRouter();
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data,
    error,
  } = useArticlesPaginatedQuery({ limit: 9, ...query });
  if (error) return <p>{error.message}</p>;
  return (
    <>
      {/*@ts-ignore*/}
      {!isLoading && data?.pages[0].docs === 0 && (
        <div className="w-full py-10 bg-accent-1 flex justify-center items-center">
          <div className="flex flex-col justify-center items-start">
            <h4>
              No resources found. Please try searching for something else.
            </h4>
          </div>
        </div>
      )}
      <Grid>
        {/* @ts-ignore */}
        {isLoading && !data?.pages?.length ? (
          <ResourceItemLoader limit={9} uniqueKey="search-resource" />
        ) : (
          data?.pages?.map((page) => {
            //@ts-ignore
            return page?.articles?.map((article: Article) => (
              <ResourceItem
                key={`resource--key${article.id}`}
                resource={article}
              />
            ));
          })
        )}
      </Grid>
      {hasNextPage && (
        <div className="text-center pt-8 xl:pt-14 mb-10">
          <Button
            loading={isFetchingNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
            onClick={() => fetchNextPage()}
            variant="cta"
          >
            Load more resources
          </Button>
        </div>
      )}
    </>
  );
};
