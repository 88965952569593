import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { BsCheck, BsChevronDown } from 'react-icons/bs';
import router, { useRouter } from 'next/router';

import { useUI } from '@contexts/ui.context';

interface ISelected {
  label: string | null;
  value: string | null;
  setSelected: () => void;
}

interface IOption {
  label: string;
  value: string;
}

interface IProps {
  options: IOption[];
  placeholder: string;
  className?: string;
  type?: 'CATEGORY' | 'TECHNOLOGY';
}

export default function Select({
  options,
  placeholder,
  className,
  type,
}: IProps) {
  const [selected, setSelected] = useState<ISelected>();

  const { setCategoryFilter, setTechnologyFilter } = useUI();

  const { query, pathname } = useRouter();

  useEffect(() => {
    Object.keys(query).length === 0 && setSelected(undefined);
    return () => {};
  }, [query, pathname]);

  useEffect(() => {
    router.push(
      {
        pathname,
      },
      undefined,
      { scroll: false }
    );
    setCategoryFilter(null);
    setTechnologyFilter(null);
    return () => {};
  }, []);

  useEffect(() => {
    const { ...restQuery } = query;

    if (selected === undefined) return;
    if (type === 'CATEGORY') {
      setCategoryFilter(selected?.value);
      router.push(
        {
          pathname,
          query: {
            ...restQuery,
            category: selected?.value,
          },
        },
        undefined,
        { scroll: false }
      );
    }

    if (type === 'TECHNOLOGY') {
      setTechnologyFilter(selected?.value);
      router.push(
        {
          pathname,
          query: {
            ...restQuery,
            technology: selected?.value,
          },
        },
        undefined,
        { scroll: false }
      );
    }

    return () => {};
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className={`relative ${className}`}>
          <Listbox.Button
            className={`group relative w-full text-sm lg:text-base py-4 pr-16 text-left border-b ${
              open ? 'border-b-telesoft' : 'border-b-heading'
            } focus:outline-none focus-visible:border-telesoft`}
          >
            <span className="block truncate">
              {/* @ts-ignore */}
              {selected ? selected.label : `Filter by ${placeholder}`}
            </span>
            <span
              className={`absolute opacity-30 text-heading inset-y-0 right-0 flex items-center pr-2 text-sm pointer-events-none`}
            >
              <BsChevronDown
                className={`text-md transition duration-300 ${
                  open && ' ease-in-out transform -rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition transform ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 translate-y-20"
            enter="transition transform ease-out duration-200"
            enterFrom="opacity-0 translate-y-20"
            enterTo="opacity-100"
          >
            <Listbox.Options
              className={`absolute w-full text-sm md:w-auto z-20 md:left-0 py-4 mt-0 md:text-base bg-heading text-white shadow-header focus:outline-none ${className}`}
            >
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `${active ? 'bg-gray-600' : null}
                          cursor-pointer text-white select-none relative py-2 pr-8 pl-10`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium' : 'font-normal'
                        } block whitespace-nowrap`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span
                          className={`${active ? '' : ''}
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <BsCheck className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
