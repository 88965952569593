import Select from '@components/ui/select';
import React, { ReactElement } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useForm } from 'react-hook-form';

import Button from '@components/ui/button';
import { useRouter } from 'next/router';
import { useUI } from '@contexts/ui.context';
import Grid from '@components/grid';

interface Props {
  categories: any;
  technologies: any;
}

export default function ArticleFilterInputs({
  categories,
}: // technologies,
Props): ReactElement {
  const router = useRouter();

  const { query, pathname } = router;

  const { setTechnologyFilter, setCategoryFilter } = useUI();

  const { handleSubmit, reset, register } = useForm();

  function onSubmit(data: any) {
    router.push(
      {
        pathname,
        query: {
          text: data.searchArticles,
        },
      },
      undefined,
      { scroll: false }
    );
    setTechnologyFilter(null);
    setCategoryFilter(null);
  }

  const formattedCategories = categories?.map((category: any) => {
    return {
      label: category.title,
      value: category.slug,
    };
  });

  // const formattedTechnology = technologies?.map((technology: any) => {
  //   return {
  //     label: technology.title,
  //     value: technology.slug,
  //   };
  // });
  return (
    <Grid className="gap-1 sm:gap-2 md:gap-8">
      {/* <div className="col-span-full md:col-span-2 lg:col-span-4"> */}
      {/*   <Select */}
      {/*     placeholder={"Technology"} */}
      {/*     options={formattedTechnology} */}
      {/*     type="TECHNOLOGY" */}
      {/*   /> */}
      {/* </div> */}
      <div className="col-span-full md:col-span-2 lg:col-span-4">
        <Select
          placeholder={'Category'}
          options={formattedCategories}
          type="CATEGORY"
        />
      </div>

      <div className="col-span-full md:col-span-4">
        <form className="relative" onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register('searchArticles')}
            placeholder="Search nucleus"
            className="w-full py-4 text-sm lg:text-base focus:outline-none transform transition-all duration-300 text-heading border-b focus:border-telesoft truncate overflow-ellipsis pl-9 border-heading"
          />
          <BsSearch className="text-heading opacity-30 absolute left-2 top-[18px] text-sm" />
          <button type="submit" className="hidden"></button>
        </form>
      </div>

      {Object.keys(query).length > 0 && (
        <div className="col-span-full">
          <Button
            variant="flat"
            type="button"
            onClick={() => {
              reset();
              router.push(router.pathname, undefined, { scroll: false });
            }}
            className="mt-4 md:mt-0 whitespace-nowrap truncate overflow-ellipsis"
          >
            Clear filters
          </Button>
        </div>
      )}
    </Grid>
  );
}
