import Link from 'next/link';
import { ROUTES } from '@constants/routes';
import { Category } from '@constants/types';
import { BsChevronRight } from 'react-icons/bs';
import Text from '@components/ui/text';
import { IoMdReturnRight } from 'react-icons/io';

interface Props {
  category: Category;
}

const TechnologyListCard: React.FC<Props> = ({ category }) => {
  const { title, description } = category;
  return (
    <Link href={`${ROUTES.CATEGORY}/${category.slug}`}>
      <a className="group flex relative justify-between items-center py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3.5 transition border-b h-full border-heading hover:border-telesoft">
        <div className="flex justify-start items-start">
          <IoMdReturnRight className="text-black mt-[3px] text-sm md:mt-1 lg:mt-1.5 md:text-base mr-2" />
          <div className="flex flex-col items-start">
            <Text variant="heading" className="text-left text-black">
              {title}
            </Text>
            {description && (
              <Text className="text-sm mt-0.5 font-light">{description}</Text>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <BsChevronRight
            size="18"
            className="hidden 2xl:block text-sm text-heading transition-transform duration-300 group-hover:translate-x-2"
          />
        </div>
      </a>
    </Link>
  );
};

export default TechnologyListCard;
