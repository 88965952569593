import ContentLoader from 'react-content-loader';

const ResourceCardLoader = (props: any) => (
  //TODO make responsive
  <ContentLoader
    speed={2}
    width={334}
    height={545}
    viewBox="0 0 334 545"
    backgroundColor="#F6F6F5"
    foregroundColor="#ecebeb"
    className="w-full h-auto col-span-4"
    {...props}
  >
    <rect x="15" y="453" width="180" height="8" />
    <rect x="15" y="482" width="280" height="6" />
    <rect x="15" y="515" width="80" height="8" />
    <rect x="0" y="0" rx="6" ry="6" width="334" height="430" />
  </ContentLoader>
);

export default ResourceCardLoader;
