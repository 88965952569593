import TechnologyListCard from '@components/common/technology-list-card';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from 'swiper/react';
import { useOrderedCategoriesQuery } from 'queries/categories/get-ordered-categories';
import Alert from '@components/ui/alert';
import ArticleSliderCard from '@components/common/article-slider-card';
import Grid from '@components/grid';
import { ASSET_URL } from 'lib/constants';

interface Props {
  className?: string;
  articles: any;
}

const ArticleCategoryHighlights: React.FC<Props> = ({ articles }) => {
  const featuredArticles = articles?.filter((article: any) => {
    return article.featured === true;
  });

  const formattedArticles = featuredArticles?.map((article: any) => {
    return {
      id: article.id,
      title: article.title,
      slug: article.slug,
      category: article.category,
      technology: article.technology,
      image: {
        mobile: {
          url: ASSET_URL + '/' + article.image.filename,
          width: 480,
          height: 275,
        },
        desktop: {
          url: ASSET_URL + '/' + article.image.filename,
          width: 1800,
          height: 800,
        },
      },
    };
  });

  const {
    data: orderedCategories,
    isLoading,
    isError,
  } = useOrderedCategoriesQuery({ limit: 10 });

  return (
    <Grid className="">
      <div className="col-span-full lg:col-span-7">
        <Carousel
          pagination={{
            clickable: true,
          }}
          effect="cube"
          paginationVariant="hero"
        >
          {formattedArticles?.map((article: any) => (
            <SwiperSlide key={`banner--key--${article.id}`}>
              <ArticleSliderCard
                article={article}
                href={`/blog/${article.slug}`}
              />
            </SwiperSlide>
          ))}
        </Carousel>
      </div>

      {isError ? (
        <Alert message={'500 - Failed to load categories.'} />
      ) : (
        <div className="col-span-full lg:col-span-5 flex flex-col justify-evenly">
          {!isLoading &&
            orderedCategories &&
            orderedCategories
              ?.slice(0, 4)
              .map((category: any) => (
                <TechnologyListCard
                  key={`category--key${category.id}`}
                  category={category}
                />
              ))}
        </div>
      )}
    </Grid>
  );
};

export default ArticleCategoryHighlights;
