import { useForm } from 'react-hook-form';
import Text from '@components/ui/text';
import Input from '@components/ui/input';
import Grid from '@components/grid';
import Container from '@components/ui/container';
import { useSnapshot } from 'valtio';
import { formStore } from '@store/form-store';
import { onSubmitForm, TFormFields } from '@lib/onSubmitForm';
import FormSubmitButton from '@components/ui/form-submit-button';
import { useState } from 'react';
import Image from 'next/image';
import { ASSET_URL } from '@lib/constants';

export default function SubscribeToNucleusCTA(block: any) {
  const {
    title = 'Subscribe to Nucleus blog updates.',
    description = 'Subscribe to our newsletter and stay updated.',
  } = block;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const snap = useSnapshot(formStore);
  const isLoading = snap.isLoading;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TFormFields>();

  if (snap.fields.email) {
    setValue('email', snap.fields.email);
  }

  function onSubmit(fields: TFormFields) {
    onSubmitForm(
      'Blog Email List',
      false,
      fields,
      'form-submissions',
      setIsSubmitted,
      reset
    );
  }

  return (
    <Container>
      <Grid className="relative overflow-hidden bg-heading p-8 md:p-12 lg:p-20">
        <div className="z-10 col-span-full md:col-span-4 self-center">
          <Text variant="subHeading" className="text-white">
            {title}
          </Text>
        </div>

        <div className="z-10 col-span-full md:col-span-4 text-white">
          <Text variant="body" className="text-white pb-4 text-opacity-70">
            {description}
          </Text>
        </div>
        {isSubmitted && (
          <div className="z-10 col-span-4 p-4 bg-telesoft text-black">
            Thank you for subscribing to Nucleus.
          </div>
        )}

        {!isSubmitted && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="z-20 col-span-full md:col-span-4 flex flex-col xl:flex-row xl:justify-end items-stretch xl:items-end"
            noValidate
          >
            <Input
              placeholderKey="Type your email..."
              type="email"
              variant="light"
              className=" text-white flex-grow xl:mr-8"
              inputClassName="bg-transparent"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please provide a valid email',
                },
              })}
              errorKey={errors.email?.message}
            />
            <FormSubmitButton
              buttonClassName="text-white"
              isLoading={isLoading}
            />
          </form>
        )}
        <Image
          src={ASSET_URL + '/' + 'telesoft-abstract-42.jpeg'}
          layout="fill"
          loading="lazy"
          alt={'Subscribe to Nucleus'}
          className="z-0 object-cover object-center opacity-20"
        />
      </Grid>
    </Container>
  );
}
