import Link from '@components/ui/link';
import Image from 'next/image';
import type { FC } from 'react';
import { useWindowSize } from '@utils/use-window-size';
import cn from 'classnames';
import { LinkProps } from 'next/link';
import useHasMounted from '@utils/use-has-mounted';
import { motion } from 'framer-motion';
import Text from '@components/ui/text';
import { Post, Category } from '@payload/types';

interface ArticleSliderCardProps {
  article: Post;
  variant?: 'rounded' | 'default';
  className?: string;
  classNameInner?: string;
  href: LinkProps['href'];
}

function getImage(deviceWidth: number, imgObj: any) {
  return deviceWidth < 480 ? imgObj.mobile : imgObj.desktop;
}

const ArticleSliderCard: FC<ArticleSliderCardProps> = ({
  article,
  className,
  classNameInner,
  href,
}) => {
  const { width, height } = useWindowSize();
  const { title, image } = article;
  const selectedImage = getImage(width, image);
  const hasMounted = useHasMounted();
  if (!hasMounted) return null;
  //@ts-ignore
  const category: Category = article?.category;
  return (
    <div
      style={{ height: height * 0.5 }}
      className={cn('mx-auto relative', className)}
    >
      <Link
        href={href}
        className={cn(
          'group flex justify-center overflow-hidden z-20',
          classNameInner
        )}
      >
        <Image
          src={selectedImage.url}
          layout="fill"
          priority
          alt={title}
          quality={75}
          className={cn(
            'bg-gray-300 object-cover transform group-hover:scale-105 transition-all duration-300 object-center w-full'
          )}
        />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="absolute flex flex-col justify-start items-start bottom-16 left-6 pr-8 md:left-8 lg:left-10 text-white z-10"
        >
          <h5 className="mb-4 text-sm py-2 border-b text-white text-opacity-70 border-opacity-40 group-hover:text-opacity-100 group-hover:border-opacity-100 group-hover:border-telesoft transition-all duration-300">
            {category?.title}
          </h5>
          <Text variant="mediumHeading" className="text-white max-w-xl">
            {article.title}
          </Text>
        </motion.div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 z-0" />

        <div className="group hidden select-none absolute bottom-0 right-40 lg:right-48 h-16 lg:h-20 text-sm lg:text-base text-white lg:flex justify-center items-center">
          <div className="h-[1px] bg-white w-20 group-hover:w-28 transition-all duration-300"></div>
          <span className="ml-4">Read Article</span>
        </div>
      </Link>
    </div>
  );
};

export default ArticleSliderCard;
