import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import qs from 'qs';

import { QueryOptionsType, Article } from '@constants/types';
import { API_ENDPOINTS } from '@constants/api-endpoints';

type PaginatedArticle = {
  data: Article[];
  nextPage: string | null;
  prevPage: string | null;
  pageParam: any;
};

const fetchPaginatedArticles = async ({ queryKey, pageParam = 0 }: any) => {
  const [_key, _params] = queryKey;
  const isCategoryFilter = !!_params.category;
  const isTechnologyFilter = !!_params.technology;
  const isSearchQuery = !!_params.text;

  let query = {};

  if (isCategoryFilter && !isTechnologyFilter) {
    query = {
      'category.slug': {
        equals: _params.category,
      },
    };
  } else if (isTechnologyFilter && !isCategoryFilter) {
    query = {
      'tag.slug': {
        in: _params.technology,
      },
    };
  } else if (isCategoryFilter && isTechnologyFilter) {
    query = {
      and: [
        {
          'category.slug': {
            equals: _params.category,
          },
        },
        {
          and: [
            {
              'tag.slug': {
                in: _params.technology,
              },
            },
          ],
        },
      ],
    };
  } else if (isSearchQuery) {
    query = {
      or: [
        {
          title: {
            like: _params.text,
          },
        },
        // {
        //   content: {
        //     like: _params.text,
        //   },
        // },
        {
          summary: {
            like: _params.text,
          },
        },
      ],
    };
  }

  const stringifiedQuery = qs.stringify(
    {
      where: query,
    },
    { addQueryPrefix: true }
  );

  const response = await axios({
    method: 'GET',
    url: API_ENDPOINTS.ARTICLES + stringifiedQuery,
    params: {
      limit: _params.limit,
      page: pageParam,
      sort: '-publishedDate',
      'where[status][equals]': 'published',
    },
  });
  // console.log("queryData: ", response.data)

  const articles = await response.data.docs;
  const nextPage = await response.data.nextPage;
  const prevPage = await response.data.prevPage;
  const docs = await response.data.totalDocs;

  return {
    articles,
    nextPage,
    prevPage,
    docs,
  };
};

const useArticlesPaginatedQuery = (options: QueryOptionsType) => {
  return useInfiniteQuery<PaginatedArticle, Error>(
    [API_ENDPOINTS.ARTICLES, options],
    //@ts-ignore
    fetchPaginatedArticles,
    {
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? false,
      getNextPageParam: (lastPage) => lastPage.nextPage ?? false,
      // enabled: false
    }
  );
};

export { useArticlesPaginatedQuery, fetchPaginatedArticles };
